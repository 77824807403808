import React, { useState, useEffect } from 'react';
import axios from 'axios';
import getApiUrl from '../utils/api'; // Import the utility function
import '../styles/Events.css';

function Events() {
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const response = await axios.get(`${getApiUrl()}/api/events?populate=*`); // Use the utility function
      setEvents(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching events:', error);
      setError('Failed to load events. Please try again later.');
      setLoading(false);
    }
  };

  const renderSkeleton = () => (
    <div className="events-container skeleton">
      <div className="skeleton-text"></div>
      <div className="skeleton-text"></div>
      <div className="skeleton-text"></div>
    </div>
  );

  const renderDescription = (description) => {
    return description.map((block, index) => {
      if (block.type === 'paragraph') {
        return (
          <p key={index}>
            {block.children.map((child, childIndex) => {
              if (child.type === 'link') {
                return (
                  <a key={childIndex} href={child.url} target="_blank" rel="noopener noreferrer">
                    {child.children.map((linkChild) => linkChild.text).join('')}
                  </a>
                );
              }
              return child.text; // Return text for non-link children
            })}
          </p>
        );
      } else if (block.type === 'list') {
        return (
          <ul key={index}>
            {block.children.map((listItem, listIndex) => (
              <li key={listIndex}>
                {listItem.children.map((child) => child.text).join('')}
              </li>
            ))}
          </ul>
        );
      }
      return null;
    });
  };

  if (loading) return renderSkeleton();
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="events-container">
      {events.slice().reverse().map((event) => (
        <div key={event.id} className="event-item">
          <div className="event-content">
            <div className="event-text">
              <h2 className="event-headline">{event.attributes.Headline}</h2>
              <div className="event-description">
                {renderDescription(event.attributes.Description)}
              </div>
              <p className="event-date">
                <span className="event-date-label">Renginio data:</span>
                {new Date(event.attributes.Date).toLocaleString(undefined, { 
                  year: 'numeric', 
                  month: '2-digit', 
                  day: '2-digit', 
                  hour: '2-digit', 
                  minute: '2-digit', 
                  hour12: false 
                })}
              </p>
            </div>
            <div className="event-banner">
              <img 
                src={`${getApiUrl()}${event.attributes.Banner.data.attributes.url}`} 
                alt={`Banner for ${event.attributes.Headline}`} 
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Events;
