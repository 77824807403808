import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Carousel } from 'bootstrap';
import '../styles/Home.css';
import getApiUrl from '../utils/api'; // Import the utility function

function Home() {
  const [homepage, setHomepage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const carouselRef = useRef(null);

  useEffect(() => {
    const fetchHomepage = async () => {
      try {
        const response = await axios.get(`${getApiUrl()}/api/homepage?populate=*`); // Use the utility function
        setHomepage(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching homepage data:', error);
        setError('Failed to load homepage data. Please try again later.');
        setLoading(false);
      }
    };

    fetchHomepage();
  }, []);

  useEffect(() => {
    if (homepage && carouselRef.current) {
      const carousel = new Carousel(carouselRef.current, {
        interval: 5000,
        wrap: true,
        ride: 'carousel'
      });
      carousel.cycle();
    }
  }, [homepage]);

  const renderSkeleton = () => (
    <div className="home-container skeleton">
      <div className="row">
        <div className="col-md-12">
          <div className="skeleton-text skeleton-header"></div>
          <div className="skeleton-text"></div>
          <div className="skeleton-text"></div>
          <div className="skeleton-text"></div>
        </div>
        <div className="col-md-12">
          <div className="skeleton-carousel"></div>
        </div>
      </div>
    </div>
  );

  if (loading) return renderSkeleton();
  if (error) return <div className="error">{error}</div>;

  const attributes = homepage?.data?.attributes;
  const carouselImages = attributes?.Carousel?.data;

  return (
    <div className="home-container">
      {attributes ? (
        <div className="content-wrapper"> {/* This will handle the layout */}
          <div className="text-section col-md-6"> {/* Use col-md-6 for desktop */}
            <h2 className="home-header">{attributes.Header}</h2>
            <p>{attributes.Paragraph}</p>
          </div>
          <div className="carousel-section col-md-6"> {/* Use col-md-6 for desktop */}
            <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel" ref={carouselRef}>
              <div className="carousel-indicators">
                {carouselImages && carouselImages.map((_, index) => (
                  <button
                    key={index}
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to={index}
                    className={index === 0 ? "active" : ""}
                    aria-current={index === 0 ? "true" : "false"}
                    aria-label={`Slide ${index + 2}`}
                  ></button>
                ))}
              </div>
              <div className="carousel-inner">
                {carouselImages && carouselImages.length > 0 ? (
                  carouselImages.map((image, index) => {
                    const imageUrl = `${getApiUrl()}${image.attributes.url}`; // Use getApiUrl() to construct the image URL
                    return (
                      <div
                        key={image.id}
                        className={`carousel-item ${index === 0 ? 'active' : ''}`}
                      >
                        <img
                          src={imageUrl}
                          className="d-block w-100"
                          alt={`Slide ${index + 1}`}
                        />
                      </div>
                    );
                  })
                ) : (
                  <div className="carousel-item active">
                    <img
                      src="https://via.placeholder.com/600x300"
                      className="d-block w-100"
                      alt="Placeholder"
                    />
                  </div>
                )}
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <p>No content available</p>
      )}
    </div>
  );
}

export default Home;
