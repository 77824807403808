import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
// import { FaBars, FaTimes } from 'react-icons/fa'; // Import icons for hamburger
import logo from '../assets/images/emphobia-logo.png';
import '../styles/Header.css';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState('');
  const location = useLocation();

  useEffect(() => {
    setActiveLink(location.pathname);
    setIsMenuOpen(false); // Close menu when route changes
  }, [location]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const isActive = (path) => activeLink === path ? 'active' : '';

  return (
    <header className="header">
      <div className="logo">
        <img src={logo} alt="Logo" />
      </div>
      <nav className={`nav-menu ${isMenuOpen ? 'active' : ''}`}>
        <ul>
          <li><Link to="/home" className={isActive('/home')}>Pagrindinis</Link></li>
          <li><Link to="/events" className={isActive('/events')}>Renginiai</Link></li>
          <li><Link to="/gallery" className={isActive('/gallery')}>Galerija</Link></li>
          <li><Link to="/residency" className={isActive('/residency')}>Rezidencija</Link></li>
          <li><Link to="/contacts" className={isActive('/contacts')}>Kontaktai</Link></li>
        </ul>
      </nav>
      <div className={`hamburger ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </header>
  );
}

export default Header;
