import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/Residency.css';
import getApiUrl from '../utils/api'; // Import the utility function

function Residency() {
  const [loading, setLoading] = useState(true);
  const [residencies, setResidencies] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchResidencies();
  }, []);

  const fetchResidencies = async () => {
    try {
      const response = await axios.get(`${getApiUrl()}/api/residencies?populate=*`); // Use the utility function
      setResidencies(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching residencies:', error);
      setError('Failed to load residencies. Please try again later.');
      setLoading(false);
    }
  };

  const renderSkeleton = () => (
    <div className="residency-container skeleton">
      <div className="row">
        <div className="col-md-12">
          <div className="skeleton-text"></div>
          <div className="skeleton-text"></div>
          <div className="skeleton-text"></div>
        </div>
        <div className="col-md-12">
          <div className="skeleton-soundcloud"></div>
        </div>
      </div>
    </div>
  );

  if (loading) return renderSkeleton();
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="residency-container">
      {residencies.map((residency) => (
        <div key={residency.id} className="residency-item">
          <div className="residency-item-text">
            <h2 className="residency-artist-name">{residency.attributes.ArtistName}</h2>
            <p className="residency-description">{residency.attributes.Description}</p>
          </div>
          <div className="residency-item-soundcloud">
            <iframe
              title={`SoundCloud player for ${residency.attributes.ArtistName}`}
              width="100%"
              height="300"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src={`https://w.soundcloud.com/player/?url=${encodeURIComponent(residency.attributes.Soundcloud)}&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true`}
            ></iframe>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Residency;
