import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap
import '../styles/Body.css';  // Import custom CSS
import VideoBanner from './VideoBanner';
import Home from './Home';
import Gallery from './Gallery';
import Events from './Events';
import Residency from './Residency';
import Contacts from './Contacts';

function Body() {
  const location = useLocation();

  return (
    <main>
      <VideoBanner />
      <TransitionGroup>
        <CSSTransition
          key={location.pathname}
          classNames="fade"
          timeout={800}
        >
          <Routes location={location}>
            <Route path="/home" element={<Home />} />
            <Route path="/events" element={<Events />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/residency" element={<Residency />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/" element={<Navigate replace to="/home" />} />
          </Routes>
        </CSSTransition>
      </TransitionGroup>
    </main>
  );
}

export default Body;
