import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import '../styles/CountdownTimer.css';

function CountdownTimer() {
  const [countdown, setCountdown] = useState({
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00'
  });
  const [visible, setVisible] = useState(false);
  const [eventDate, setEventDate] = useState(null); // New state for event date
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchEvents = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_PUBLIC_STRAPI_URL || 'https://emphobia-strapi-cms-5b337f006b8f.herokuapp.com'}/api/events?populate=*`);
      const events = response.data.data;
      const upcomingEvents = events.filter(event => new Date(event.attributes.Date) > new Date());
      if (upcomingEvents.length > 0) {
        const soonestEvent = upcomingEvents.reduce((prev, curr) => 
          new Date(prev.attributes.Date) < new Date(curr.attributes.Date) ? prev : curr
        );
        setEventDate(new Date(soonestEvent.attributes.Date)); // Set the soonest event date
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching events:', error);
      setError('Failed to load events. Please try again later.');
      setLoading(false);
    }
  };

  const updateCountdown = useCallback(() => {
    if (!eventDate) return; // Exit if no event date is set

    const now = new Date();
    const timeDiff = eventDate - now;

    if (timeDiff <= 0) {
      setCountdown({
        days: '00',
        hours: '00',
        minutes: '00',
        seconds: '00'
      });
      return;
    }

    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

    const padWithZero = (num) => String(num).padStart(2, '0');

    setCountdown({
      days: padWithZero(days),
      hours: padWithZero(hours),
      minutes: padWithZero(minutes),
      seconds: padWithZero(seconds)
    });
  }, [eventDate]);

  useEffect(() => {
    fetchEvents(); // Fetch events on component mount
  }, []);

  useEffect(() => {
    if (eventDate) {
      updateCountdown();
      const interval = setInterval(updateCountdown, 1000);
      setTimeout(() => setVisible(true), 100);
      return () => clearInterval(interval);
    }
  }, [updateCountdown, eventDate]);

  if (loading) return <div></div>; // Loading state
  if (error) return <div className="error">{error}</div>; // Error state

  return (
    <div className={`countdown-timer ${visible ? 'visible' : ''}`}>
      {Object.entries(countdown).map(([unit, value], index) => (
        <React.Fragment key={unit}>
          <div className="countdown-item">
            <div className='countdown-number'>{value}</div>
            <div className="countdown-label">{unit}</div>
          </div>
          {index < 3 && <div className="colon">:</div>}
        </React.Fragment>
      ))}
    </div>
  );
}

export default CountdownTimer;
