import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '../styles/Footer.css';

function Footer({ font = 'Arial, sans-serif' }) {
    return (
        <footer className="footer" style={{
            "--email-icon-margin": "10px",
            "fontFamily": font
        }}>
            <div className="footer-container">
                <div className="footer-column">
                    <div className="footer-item">
                        <p><i className="fas fa-envelope"></i> <a href="mailto:noriu@emphobiaevents.info">noriu@emphobiaevents.info</a></p> 
                    </div>
                    <div className="footer-item">
                        <p>&copy; 2024 Emphobia</p>
                    </div>
                </div>
                <div className="footer-column">
                    <div className="footer-item">
                        <ul className="social-icons">
                            <li>
                                <a href="https://facebook.com/emphobiatm" target="_blank" rel="noopener noreferrer" className="social-icon">
                                    <i className="fab fa-facebook-f"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/emphobia_/" target="_blank" rel="noopener noreferrer" className="social-icon">
                                    <i className="fab fa-instagram"></i>
                                </a>
                            </li>
                        </ul>
                    </div>   
                </div>
            </div>
        </footer>
    );
}

export default Footer;
