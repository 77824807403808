import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/Contacts.css';
import { FaEnvelope, FaPhone, FaMapMarkerAlt } from 'react-icons/fa';
import getApiUrl from '../utils/api'; // Import the utility function

function Contact() {
  const [contactInfo, setContactInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchContactInfo = async () => {
      try {
        const response = await axios.get(`${getApiUrl()}/api/contact`); // Use the utility function
        console.log('API Response:', response.data);
        setContactInfo(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching contact info:', error);
        setError('Failed to load contact information. Please try again later.');
        setLoading(false);
      }
    };

    fetchContactInfo();
  }, []);

  const renderSkeleton = () => (
    <div className="contact-container skeleton">
      <div className="row">
        <div className="col-md-6">
          <div className="skeleton-text skeleton-header"></div>
          <div className="skeleton-text"></div>
          <div className="skeleton-text"></div>
          <div className="skeleton-text"></div>
        </div>
        <div className="col-md-6">
          <div className="skeleton-map"></div>
        </div>
      </div>
    </div>
  );

  if (loading) return renderSkeleton();
  if (error) return <div className="error">{error}</div>;

  const attributes = contactInfo?.data?.attributes;

  console.log('Contact attributes:', attributes);

  return (
    <div className="contact-container">
      {attributes ? (
        <div className="row">
          <div className="col-md-6">
            <div className="contact-info">
              <h2 className="contact-header">Susisiekite su mumis</h2>
              <div className="contact-items-container">
                <div className="contact-item">
                  <FaEnvelope className="contact-icon" />
                  <div className="contact-text">
                    <h2>El. Paštas</h2>
                    {attributes.Email ? (
                      <p className="email-container">
                        <a href={`mailto:${attributes.Email}`}>{attributes.Email}</a>
                      </p>
                    ) : (
                      <p>No email available</p>
                    )}
                  </div>
                </div>
                <div className="contact-item">
                  <FaPhone className="contact-icon" />
                  <div className="contact-text">
                    <h2>Telefono Nr.</h2>
                    {attributes.Phone ? (
                      <p><a href={`tel:${attributes.Phone}`}>{attributes.Phone}</a></p>
                    ) : (
                      <p>No phone number available</p>
                    )}
                  </div>
                </div>
                <div className="contact-item">
                  <FaMapMarkerAlt className="contact-icon" />
                  <div className="contact-text">
                    <h2>Adresas</h2>
                    {attributes.Address ? (
                      <p>{attributes.Address}</p>
                    ) : (
                      <p>No address available</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="map-container">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d73438.85772686043!2d23.856371487491344!3d54.8859952206288!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x46e72326e78a318f%3A0xf5386492d0d9bf38!2sPranc%C5%BEz%C5%B0%20g.%202%2C%20Kaunas%2C%2044439%20Kauno%20m.%20sav.!3m2!1d54.886023099999996!2d23.938771499999998!5e0!3m2!1sen!2slt!4v1729515814045!5m2!1sen!2slt"
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Location map for Prancūzų g. 2, Kaunas"
              ></iframe>
            </div>
          </div>
        </div>
      ) : (
        <p>No contact information available</p>
      )}
    </div>
  );
}

export default Contact;
