import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import banner from '../assets/videos/banner.mp4';
import CountdownTimer from './CountdownTimer';
import '../styles/VideoBanner.css';

function VideoBanner() {
  const [pageTitle, setPageTitle] = useState('');
  const location = useLocation();

  const isHomePage = (pathname) => {
    return pathname === '/' || pathname === '/home';
  };

  useEffect(() => {
    if (!isHomePage(location.pathname)) {
      setPageTitle(getPageTitle(location.pathname));
    }
  }, [location.pathname]);

  const renderContent = () => {
    if (isHomePage(location.pathname)) {
      return (
        <>
          <CountdownTimer />
          <div className="event-title">
            <h2>ŠIURPAI #EGZORCIZMAS // P.A.R.A.K.A.S</h2>
          </div>
          <button 
            className="event-button"
            onClick={() => window.location.href = '/events'}>
              Daugiau
          </button>
        </>
      );
    } else {
      return (
        <div className="page-title">
          <h2>{pageTitle}</h2>
        </div>
      );
    }
  };

  const getPageTitle = (pathname) => {
    switch (pathname) {
      case '/events':
        return 'Renginiai';
      case '/gallery':
        return 'Galerija';
      case '/home':
        return 'Pagrindinis';
      case '/residency':
        return 'Rezidencija';
      case '/contacts':
        return 'Kontaktai'; 
      // Add more cases as needed
      default:
        return 'Page';
    }
  };

  return (
    <div className="video-banner">
      <video className="banner-video" autoPlay loop muted playsInline>
        <source src={banner} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {renderContent()}
    </div>
  );
}

export default VideoBanner;
