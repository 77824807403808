import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/Gallery.css';
import getApiUrl from '../utils/api'; // Import the utility function

function Gallery() {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(`${getApiUrl()}/api/gallery?populate=*`); // Use the utility function
        if (response.data && response.data.data && response.data.data.attributes && response.data.data.attributes.Images) {
          setImages(response.data.data.attributes.Images.data);
        } else {
          throw new Error('Unexpected data structure');
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching gallery images:', error);
        setError('Failed to load gallery images. Please try again later.');
        setLoading(false);
      }
    };

    fetchImages();
  }, []);

  const openImage = (image) => {
    setSelectedImage(image);
  };

  const closeImage = (e) => {
    if (e) {
      e.stopPropagation();
    }
    setSelectedImage(null);
  };

  const getImageUrl = (image, format = 'medium') => {
    if (image.attributes.formats && image.attributes.formats[format]) {
      return `${getApiUrl()}${image.attributes.formats[format].url}`; // Use the utility function
    }
    return `${getApiUrl()}${image.attributes.url}`; // Use the utility function
  };

  const renderSkeleton = () => (
    <section id="gallery" className="gallery-container">
      <div className="gallery-grid">
        {[...Array(12)].map((_, index) => (
          <div key={index} className="gallery-item skeleton"></div>
        ))}
      </div>
    </section>
  );

  if (loading) return renderSkeleton();
  if (error) return <div className="error">{error}</div>;

  return (
    <section id="gallery" className="gallery-container">
      {images.length > 0 ? (
        <>
          <div className="gallery-grid">
            {images.map((image) => (
              <div key={image.id} className="gallery-item" onClick={() => openImage(image)}>
                <img 
                  src={getImageUrl(image, 'medium')} 
                  alt={''} 
                />
              </div>
            ))}
          </div>
        </>
      ) : (
        <p>No images available</p>
      )}

      {selectedImage && (
        <div className="fullscreen-overlay" onClick={closeImage}>
          <div className="fullscreen-image-container">
            <img 
              src={getImageUrl(selectedImage, 'large')} 
              alt={''} 
            />
            <button className="close-button" onClick={closeImage}>×</button>
          </div>
        </div>
      )}
    </section>
  );
}

export default Gallery;
